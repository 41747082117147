<template>
  <div>
    <c-tab
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :key="tab.key"
          :is="tab.component"
          :popupParam="popupParam"
          :check.sync="check"
          :isOld="isOld"
          :disabled="disabled"
          :contentHeight="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dangerRegularCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info', //처음 화면에 보여질 tab을 표시하는 부분
      check: {
        chmDangerRegularCheckId: '',
        chmDangerMstId: '',
        chmDangerMstName: '',
        chmDangerMstTypeId: '',
        plantCd: '',
        chmDangerTypeCd: null,
        chmDangerLocation: '',
        chmDangerCheckName: '',
        chmDangerPermitNo: '',
        chmDangerUserId: '',
        chmDangerManageUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerInspectorUserId: '',
        chmStatusDangerRegularCheckCd: '',
        chmDangerCount: '',
        installPermitDate: '',
        chmDangerHandlingOverview: '',
        chmDangerStartDate: '',
        chmDangerEndDate: '',
        period: [],
        checkResults: [],
        deleteCheckResults: [],
        types: [],
      },
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.chmDangerRegularCheckId)
    },
    disabled() {
      return this.check.chmStatusDangerRegularCheckCd === 'CSDRCC0002';
    },
    tabHeight() {
      return String(this.contentHeight - 60);
    },
    tabItems() {
      let items = [{ key: uid(), name: 'info', icon: 'construction', label: '점검 정보', component: () => import(`${'./dangerRegularCheckInfo.vue'}`) },];
      if (this.isOld) {
        items.push({ key: uid(), name: 'etc', icon: 'edit', label: '위험물 저장소 부가정보', component: () => import(`${'./dangerRegularCheckEtc.vue'}`) })
      }
      return items;
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.danger.regularChecks.get.url
      // code setting
      // list setting  
      this.getDetail()
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.chmDangerRegularCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.check = _result.data;
          this.check.period = [_result.data.chmDangerStartDate, _result.data.chmDangerEndDate];
        },);
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>